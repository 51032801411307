<template>

    <v-row class="mt-4">
        <v-col cols="12">
            <v-card
            v-if="loggedIn"
            class="mx-auto"
                >
    
                <v-toolbar
                    ligth
                    cards                
                    flat
                    color="green lighten-4"
                >
                    <v-card-title class="text-h6 font-weight-regular">
                    Kontakte
                    </v-card-title>
                    <v-spacer> </v-spacer>
                    <v-btn
                        :disabled="currentUser.roleLevel < 500"
                        color="primary"
                        dark
                        fab
                        class="mt-10"
                        @click="addItem"
                    >
                        <v-icon dark>
                            mdi-playlist-plus
                        </v-icon>
                    </v-btn>             
                </v-toolbar>
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                        <v-dialog v-model="ctrlConfirmDel.isVisisble" :width="ctrlConfirmDel.width">
                            <ctrlConfirmDel 
                                ref="ctrlConfirmDel" 
                                :text= "ctrlConfirmDel.text"
                                :delName="ctrlConfirmDel.delName"
                                @deleteItemConfirm="deleteItemConfirm"
                                @close="closeConfirmDel">
                            </ctrlConfirmDel>              
                        </v-dialog>
                        <v-dialog v-model="ctrlAEItem.isVisisble" :width="ctrlAEItem.width" >
                            <ctrlAddEditItem 
                                ref="ctrlAEItem" 
                                :item="ctrlAEItem.item"
                                :options="ctrlAEItem.options"
                                :isVisible.sync="ctrlAEItem.isVisisble"                                
                                @close="ctrlAEItem.isVisisble = false">
                            </ctrlAddEditItem>              
                        </v-dialog>       
                        <v-dialog v-model="ctrlAEC2C.isVisisble" :width="ctrlAEC2C.width" >
                            <ctrlAddEditCompany2Contact 
                                ref="ctrlAEC2C" 
                                :item ="ctrlAEC2C.item"
                                :options="ctrlAEC2C.options"
                                :isVisible.sync="ctrlAEC2C.isVisisble"                              
                                @close="ctrlAEC2C.isVisisble = false">
                            </ctrlAddEditCompany2Contact>              
                        </v-dialog>       
                                         
                    <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    >
                    <template v-slot:item.enabled="{ item }">
                        <v-switch
                            v-model="item.enabled"
                            :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel)"
                            @change="enabledChanged(item)"
                            
                        ></v-switch>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon                            
                            
                            :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel) && currentUser.id != item.id"
                            class="mr-2"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            :disabled="!access.delete_accept || item.roleLevel > currentUser.roleLevel"
                            
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon> 
                    </template>               
                    <template  v-slot:item.linked_companies="{ item }">
                        <v-icon
                            
                            :disabled="currentUser.roleLevel < 750 || item.roleLevel > currentUser.roleLevel"
                            large
                            @click="linkCompany(item)"
                        >
                            mdi-link-box
                        </v-icon>
                    </template>
                </v-data-table>
  
            </v-card>
        </v-col>
    </v-row>

</template>


<script>

// import helper from "../../../helper/index"

export default {
    
    components: {
        ctrlAddEditItem: () => import('../../../components/ctrl/addEditContact.vue'),
        ctrlAddEditCompany2Contact: () => import('../../../components/ctrl/addEditCompany2Contact.vue'),
        ctrlConfirmDel: () => import('../../../components/ctrl/confirmDeleteItem.vue'),
        // dlgShowMap,
        // dlgImportFields,
    },  


    data () {
        return {
            name: "ContactsViewer",
            // access: {
            //     edit_accept: undefined
            // },
            errMsg:'',
            search: '',
            headers: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Kontakt ID', value: 'id',},
                { text: 'Vorname', value: 'firstname',},
                { text: 'Nachname', value: 'lastname',},
                { text: 'E-Mail', value: 'email' },
                 { text: 'Betriebe',sortable: false, value: 'linked_companies' },
                { text: 'Aktiv',sortable: false, value: 'enabled' }    
            ],  
            editedIndex: -1,
            ctrlAEItem:{
                isVisisble: false,
                width: 900,
                item:{},
                options:{
                    title: 'Kontakt',  
                    isEdit: false
                }
            },
           ctrlAEC2C:{
                isVisisble: false,
                width: 900,
                item:{},
                options:{
                    title: 'Linked Company',  
                    isEdit: false
                }
            },             
            ctrlConfirmDel:{
                width: 500,
                isVisisble: false,
                text: "Wollen Sie den User wirklich löschen?" ,
                delName: '',
                item: undefined
            },
        }
    },

  
    computed: {


        loggedIn: function() {        
            return this.$store.state.auth.status.loggedIn;       
        },

        currentUser() {
            console.debug("user", this.$store.state.auth.user)
            return this.$store.state.auth.user;
        },

        items(){
            console.debug("------------------------", this.$store.state.management.contacts)
            return this.$store.state.management.contacts;
        },

        access(){
            
            return this.$store.state.management.access;
        },  
        
         
    },
    methods:{


        addItem () {
            console.debug('addItem');
            this.ctrlAEItem.options.isEdit = false;
            this.ctrlAEItem.item = undefined
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },  

        editItem (item) {
            console.debug('editItem',item);
            this.ctrlAEItem.options.isEdit = true;
            this.ctrlAEItem.item = item;
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },


        deleteItem (item) {
            console.debug('deleteItem', item);
            this.ctrlConfirmDel.delName = item.firstname + " " + item.lastname
            this.ctrlConfirmDel.isVisisble = true;
            this.ctrlConfirmDel.item = item;
        },

        closeConfirmDel(){
             console.debug('closeConfirmDel');
             this.ctrlConfirmDel.isVisisble = false;        
        },        

        deleteItemConfirm () {
            console.debug('deleteItemConfirm');
           this.$store.dispatch("management/deleteApiSetStore", {path:"api/management/contact", param:{ store:"contacts", 'id':this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("User deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },

        enabledChanged(item){
            console.debug('enabledChanged', item);
            this.$store.dispatch('management/putApiSetStore', {path:"api/management/contact", param:{ store:"contacts", data:item}})
            .then((response)=>{ 

                console.log("enabledChanged:", response)

            })
            .catch((error)=>{
                console.error(error);
            })

 
        },



        linkCompany(item){
          console.debug('linkCompany', item);
          this.ctrlAEC2C.item = item;
          this.ctrlAEC2C.isVisisble = true;
            
        },

 

        refreshContent: function(){
            console.debug('refreshContent');
 
            this.$store.dispatch("management/getApiSetStore", {path:"api/management/access", param:{store:"access", content:"contacts"}
            }).then( ()=>{ 
                return this.$store.dispatch("management/getApiSetStore", {path:"api/management/contacts", param:{store:"contacts"}})
            }).then( ()=>{    
                   console.debug('sdsdsd')  
            }).catch((error)=>{
                console.error(error);
            })
 
        },
    },
    mounted() {

        //this.refreshNavbar();
        console.debug('mounted');
        this.refreshContent();
    }
}
</script>